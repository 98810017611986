<template>
    
    <b-row>
      <b-col cols="12" v-if="van && track">
        <b-row>
          <b-col cols="12" class="d-flex flex-column justify-content-center align-items-center">
            <h2>Track your delivery</h2>
            <p class="text-muted m-0">Installation #{{ orderItemId }}</p>
          </b-col>
        </b-row>
        
        <b-row>
          <b-col cols="12">
            <hr>

            <b-row>
              <b-col cols="12">
                <GmapMap
                  :center="{ lat: Number(van.lat), lng: Number(van.lon) }"
                  :zoom="14"
                  map-type-id="terrain"
                  style="width: 100%; height: 300px"
                >
                  <GmapMarker
                    :position="{ lat: Number(van.lat), lng: Number(van.lon) }"
                  />
                </GmapMap>
              </b-col>
            </b-row>

            <hr>
          </b-col>
        </b-row>
        
        <b-row v-if="van">
          <b-col cols="12" class="d-flex flex-column justify-content-center align-items-center">
            <a :href="`tel:${van.phone}`" class="btn btn-secondary mt-2">Call Driver</a>
          </b-col>
        </b-row>
      </b-col>

      

      <b-col cols="12" v-if="track && !van">
        <b-row>
          <b-col cols="12" class="d-flex flex-column justify-content-center align-items-center">
            <b-spinner variant="secondary"></b-spinner>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" v-if="!track">
        <b-row>
          <b-col cols="12" class="d-flex flex-column justify-content-center align-items-center">
            <h2>Something went wrong.</h2>
          </b-col>
        </b-row>
      </b-col>

    </b-row>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      van: null,
      orderItemId: this.$route.params.id,
      trackingPath: this.$route.params.trackingPath,
      track: true
    }
  },
  mounted() {
    setInterval(() => {
      if(this.track) {
        window.axios.get(`/track-order/${this.orderItemId}/${this.trackingPath}/get`).then(response => {
          this.van = response.data
        }).catch(e => { this.track = false })
      }
    }, 2500)
  }
}
</script>

<style>

</style>
