<template>
    
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="d-flex flex-column justify-content-center align-items-center">
          <fa-icon class="display-3 mb-3 mt-3 text-primary" icon="check"/>
          <h1 class="text-primary">Thank you.</h1>
          <small class="text-muted mb-3">Your payment is processing.</small>
        </b-col>
      </b-row>
    </b-container>

</template>

<script>

export default {
  data () {
    return {
      invoice: {},
    }
  },
  mounted() {
    // Get Invoice
    window.axios.get(`payment/complete/${this.$route.params.id}`)
      .then(response => {
        this.invoice = response.data;
      })
  }
}
</script>
