<template>
    
    <b-container fluid>
      <b-row v-if="!saved">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" class="d-flex flex-column align-items-center">
              <h3>Video Route Upload</h3>
              <small class="text-muted text-center ml-2 mr-2">Please ensure the video is clear and shows the route that our installers will take clearly.</small>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-row>
                <b-col cols="12">
                  <b-form-file
                    :disabled="isSaving"
                    v-model="video"
                    :state="computedInputState"
                    :placeholder="video ? video.name : 'Choose a file or drop it here...'"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button block class="mt-3" @click="uploadVideo">Upload</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="saved">
        <b-col cols="12" class="d-flex flex-column justify-content-center align-items-center">
          <fa-icon class="display-3 mb-3 mt-3 text-primary" icon="check"/>
          <h1 class="text-primary">Thank you.</h1>
          <small class="text-muted mb-3">Your video has been successfully submitted.</small>
        </b-col>
      </b-row>
    </b-container>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      isSaving: false,
      saved: false,
      saveAttempted: false,
      orderItemId: this.$route.params.id,
      email: this.$route.params.email,

      video: null,
    }
  },
  computed: {
    computedInputState() {
      if(!this.saveAttempted) return null
      if(!this.video && this.saveAttempted) return false
      if(this.video) return true
    }
  },
  methods: {
    uploadVideo() {
      if(!this.isSaving && !this.saved && this.video) {
        this.isSaving = true

        let formData = new FormData()
        formData.append('video', this.video)

        window.axios.post('/submit-video/' + this.orderItemId + '/' + this.email, formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }).then(response => {
          if(response.data) {
            this.saved = true
          }
        }).catch(response => {
          this.isSaving = false
          this.saveAttempted = true
        })
      }
    }
  }
}
</script>
