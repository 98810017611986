<template>
  <b-form @submit.prevent="submit({ email, password })">
    <b-row v-show="otpStep == false">
      <b-col cols="12">
        <b-form-group label="Email Address" :invalid-feedback="validationInvalidFeedback(loginErrors, 'email')">
          <b-form-input class="text-monospace" :disabled="isLoggingIn" :state="validationState(loginErrors, 'email')" type="email" v-model="email"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Password" :invalid-feedback="validationInvalidFeedback(loginErrors, 'password')">
          <b-form-input class="text-monospace" :disabled="isLoggingIn" :state="validationState(loginErrors, 'password')" type="password" v-model="password"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" offset-md="8">
        <b-button block type="submit" variant="secondary"><b-spinner small v-if="isLoggingIn"></b-spinner><span v-if="!isLoggingIn">Log In</span></b-button>
      </b-col>
    </b-row>
    <b-row v-show="otpStep">
      <b-col cols="12">
        <h4 class="text-center">Two Factor Verification</h4>
        <p class="mb-4 text-muted text-center">Enter your two factor authentication code to login.</p>
      </b-col>
      <b-col cols="12">
        <b-form-group :invalid-feedback="validationInvalidFeedback(loginErrors, 'otp_code')">
          <b-form-input class="text-monospace" :disabled="isLoggingIn" :state="validationState(loginErrors, 'otp_code')" v-model="otpCode"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-button block variant="outline-primary" @click="otpStep = false">Back</b-button>
      </b-col>
      <b-col cols="12" md="6">
        <b-button block type="submit" variant="secondary"><b-spinner small v-if="isLoggingIn"></b-spinner><span v-if="!isLoggingIn">Verify</span></b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      email: '',
      password: '',
      otpCode: '',

      otpStep: false,
    }
  },
  mixins: [validation],
  computed: {
    ...mapGetters('authentication', ['isLoggingIn', 'loginErrors'])
  },
  methods: {
    ...mapActions('authentication', ['login']),
    submit ({ email, password }) {
      let data = { email, password };
      if(this.otpStep) data['otp_code'] = this.otpCode

      this.login(data).then(response => {
        if(response == '2fa-required') {
          this.otpStep = true
        }else{
          this.$router.push( ( this.$route.query.next || '/' ) )
        }
      }).catch(err => {})
    }
  }
}
</script>

<style>

</style>
